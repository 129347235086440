import React, { Suspense } from 'react';
import { reduxStore } from './store';
import { Provider } from "react-redux";
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import ErrorBoundary from './pages/ErrorBoundary';
import "./assets/styles/material-icons.css";
import "./assets/styles/style.css";
import "./assets/styles/main.css";
import 'react-tabs/style/react-tabs.css';
import "./assets/styles/ketcher.css";
import './assets/styles/print.css';
import Loading from "./Loading";

const Login = React.lazy(() => import("./pages/Login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const AccountActivate = React.lazy(() => import("./pages/AccountActivate"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const TestData = React.lazy(() => import("./pages/TestData"));

/* LEGO */
const Grid = React.lazy(() => import("./pages/Lego/Grid"));
const ReportGrid = React.lazy(() => import("./pages/Lego/ReportGrid"));
const ViewHistory = React.lazy(() => import("./pages/History/ViewHistory"));
const TestTrendReport = React.lazy(() => import("./pages/Lego/TestTrendReport"));

/* ADMINSTRATION */
const CreateSite = React.lazy(() => import("./pages/Adminstration/Site/CreateSite"));
const ViewSite = React.lazy(() => import("./pages/Adminstration/Site/ViewSite"));
const SecuritySettings = React.lazy(() => import("./pages/Adminstration/Settings/SecuritySettings"));
const AppConfigurations = React.lazy(() => import("./pages/Adminstration/Settings/AppSettings"));
const CreateUser = React.lazy(() => import("./pages/Adminstration/IdentityAccess/User/CreateUser"));
const ViewUser = React.lazy(() => import("./pages/Adminstration/IdentityAccess/User/ViewUser"));
const CreateGroup = React.lazy(() => import("./pages/Adminstration/IdentityAccess/Group/CreateGroup"));
const ViewGroup = React.lazy(() => import("./pages/Adminstration/IdentityAccess/Group/ViewGroup"));
const License = React.lazy(() => import("./pages/Adminstration/Licenses/License"));
const CreateUserType = React.lazy(() => import("./pages/Adminstration/IdentityAccess/UserTypes/CreateUserType"));
const ViewUserType = React.lazy(() => import("./pages/Adminstration/IdentityAccess/UserTypes/ViewUserType"));
const CreateRemark = React.lazy(() => import("./pages/Adminstration/Auxiliaries/DefaultRemark/CreateRemark"));
const ViewRemark = React.lazy(() => import("./pages/Adminstration/Auxiliaries/DefaultRemark/ViewRemark"));
const CreateUnit = React.lazy(() => import("./pages/Adminstration/Auxiliaries/UnitMeasurement/CreateUnit"));
const ViewUnit = React.lazy(() => import("./pages/Adminstration/Auxiliaries/UnitMeasurement/ViewUnit"));
const CreateReasonCode = React.lazy(() => import("./pages/Adminstration/Auxiliaries/ReasonCode/CreateReasonCode"));
const ViewReasonCode = React.lazy(() => import("./pages/Adminstration/Auxiliaries/ReasonCode/ViewReasonCode"));
const SignatureConfiguration = React.lazy(() => import("./pages/Adminstration/Signature/SignatureConfiguration"));
const CreateApproval = React.lazy(() => import("./pages/Adminstration/Approval/Create"));
const ViewApproval = React.lazy(() => import("./pages/Adminstration/Approval/View"));

/* MASTER */
const CreateAnalysisType = React.lazy(() => import("./pages/Masters/AnalysisType/Create"));
const ViewAnalysisType = React.lazy(() => import("./pages/Masters/AnalysisType/View"));
const CreateInvestigationChecklist = React.lazy(() => import("./pages/Masters/Checklists/InvestigationChecklist/Create"));
const ViewInvestigationChecklist = React.lazy(() => import("./pages/Masters/Checklists/InvestigationChecklist/View"));
const CreateSamplingChecklist = React.lazy(() => import("./pages/Masters/Checklists/SamplingChecklist/Create"));
const ViewSamplingChecklist = React.lazy(() => import("./pages/Masters/Checklists/SamplingChecklist/View"));
const ViewTestVariable = React.lazy(() => import("./pages/Masters/BuildingBlocks/TestVariables/View"));
const CreateCOATemplate = React.lazy(() => import("./pages/Masters/DocumentTemplate/COATemplate/Create"));
const ViewCOATemplate = React.lazy(() => import("./pages/Masters/DocumentTemplate/COATemplate/View"));
const CreateDatasheetTemplate = React.lazy(() => import("./pages/Masters/DocumentTemplate/DatasheetTemplate/Create"));
const ViewDatasheetTemplate = React.lazy(() => import("./pages/Masters/DocumentTemplate/DatasheetTemplate/View"));
const CreateSection = React.lazy(() => import("./pages/ExperimentManagement/Section/Create"));
const ViewSection = React.lazy(() => import("./pages/ExperimentManagement/Section/View"));
const CreateChemicalStructure = React.lazy(() => import("./pages/ExperimentManagement/ChemicalSrtucture/Create"));
const ViewChemicalStructure = React.lazy(() => import("./pages/ExperimentManagement/ChemicalSrtucture/View"));
const CreateTemplate = React.lazy(() => import("./pages/ExperimentManagement/Template/Create"));
const ViewTemplate = React.lazy(() => import("./pages/ExperimentManagement/Template/View"));
const CreateExperiment = React.lazy(() => import("./pages/ExperimentManagement/ExperimentCreation/Create"));
const ViewExperiment = React.lazy(() => import("./pages/ExperimentManagement/ExperimentCreation/View"));
const ViewMyExperiment = React.lazy(() => import("./pages/ExperimentManagement/MyExperiments/View"));
const ViewExperimentApproval = React.lazy(() => import("./pages/ExperimentManagement/ExperimentApproval/View"));
const ViewExperimentReleased = React.lazy(() => import("./pages/ExperimentManagement/ExperimentReleased/View"));
const ViewExperimentReport = React.lazy(() => import("./pages/ExperimentManagement/Reports/View"));
const CreateTest = React.lazy(() => import("./pages/Masters/TestRegistration/Create"));
const ConfigureTest = React.lazy(() => import("./pages/Masters/TestRegistration/ConfigureTest"));
const ViewTest = React.lazy(() => import("./pages/Masters/TestRegistration/View"));
const CreateSharedList = React.lazy(() => import("./pages/Masters/BuildingBlocks/SharedList/CreateSharedList"));
const ViewSharedList = React.lazy(() => import("./pages/Masters/BuildingBlocks/SharedList/ViewSharedList"));
const CreateTestVariables = React.lazy(() => import("./pages/Masters/BuildingBlocks/TestVariables/Create"));
const CreateCondition = React.lazy(() => import("./pages/Masters/Storage/Condition/CreateCondition"));
const ViewCondition = React.lazy(() => import("./pages/Masters/Storage/Condition/ViewCondition"));
const CreateCustomFields = React.lazy(() => import("./pages/Masters/BuildingBlocks/CustomFields/CreateCustomFields"));
const CreateSpecification = React.lazy(() => import("./pages/Masters/Specification/Create"));
const ViewSpecification = React.lazy(() => import("./pages/Masters/Specification/View"));
const CreateDatasheet = React.lazy(() => import("./pages/Masters/Datasheet/CreateDatasheet"));
const ViewDatasheet = React.lazy(() => import("./pages/Masters/Datasheet/ViewDatasheet"));
const CreateCustomer = React.lazy(() => import("./pages/Masters/Entities/Customers/CreateCustomer"));
const CreateProject = React.lazy(() => import("./pages/Masters/Projects/CreateProject"));
const ViewProject = React.lazy(() => import("./pages/Masters/Projects/ViewProject"));
const CustomFieldsTextBox = React.lazy(() => import("./pages/Masters/BuildingBlocks/CustomFields/CustomFieldTypes/CustomFieldsTextBox"));
const CreateSupplierManufacturers = React.lazy(() => import("./pages/Masters/Entities/SupplierManufacturers/CreateSupplierManufacturers"));
const ViewSupplierManufacturers = React.lazy(() => import("./pages/Masters/Entities/SupplierManufacturers/ViewSupplierManufacturers"));
const CreateApprovedSupplier = React.lazy(() => import("./pages/Masters/Entities/ApprovedSupplier/CreateApprovedSupplier"));
const ViewApprovedSupplier = React.lazy(() => import("./pages/Masters/Entities/ApprovedSupplier/ViewApprovedSupplier"));
const CreateProductMaterial = React.lazy(() => import("./pages/Masters/ProductMaterials/CreateProductMaterials"));
const ViewProductMaterials = React.lazy(() => import("./pages/Masters/ProductMaterials/ViewProductMaterials"));
const ViewCustomer = React.lazy(() => import("./pages/Masters/Entities/Customers/ViewCustomer"));
const CreateSampleType = React.lazy(() => import("./pages/Masters/SampleTypes/CreateSampleTypes"));
const ViewCustomFields = React.lazy(() => import("./pages/Masters/BuildingBlocks/CustomFields/ViewCustomFields"));
const ViewSampleType = React.lazy(() => import("./pages/Masters/SampleTypes/ViewSampleType"));
const CreateBuilding = React.lazy(() => import("./pages/Masters/Storage/Building/CreateBuilding"));
const CreateDepartment = React.lazy(() => import("./pages/Masters/Storage/Department/CreateDepartment"));
const CreateRoom = React.lazy(() => import("./pages/Masters/Storage/Room/CreateRoom"));
const CreateStorageDevice = React.lazy(() => import("./pages/Masters/Storage/Device/CreateStorageDevice"));
const CreateLocation = React.lazy(() => import("./pages/Masters/Storage/Location/CreateLocation"));
const ViewBuilding = React.lazy(() => import("./pages/Masters/Storage/Building/ViewBuilding"));
const ViewDevice = React.lazy(() => import("./pages/Masters/Storage/Device/ViewDevice"));
const ViewRoom = React.lazy(() => import("./pages/Masters/Storage/Room/ViewRoom"));
const ViewDepartment = React.lazy(() => import("./pages/Masters/Storage/Department/ViewDepartment"));
const ViewLocation = React.lazy(() => import("./pages/Masters/Storage/Location/ViewLocation"));
const ServiceProviderCreate = React.lazy(() => import("./pages/Masters/ServiceProvider/Create"));
const ServiceProviderView = React.lazy(() => import("./pages/Masters/ServiceProvider/View"));
const SpecificationReport = React.lazy(() => import("./pages/Masters/Reports/SpecificationReport"));
const StorageLocationReport = React.lazy(() => import("./pages/Masters/Reports/StorageLocationReport"));

/* CHEMICAL */
const CreateReagentName = React.lazy(() => import("./pages/Chemical/ReagentName/Create"));
const ViewReagentName = React.lazy(() => import("./pages/Chemical/ReagentName/View"));
const CreateReagentInward = React.lazy(() => import("./pages/Chemical/ReagentInward/Create"));
const ViewReagentInward = React.lazy(() => import("./pages/Chemical/ReagentInward/View"));
const CreateReagentUsage = React.lazy(() => import("./pages/Chemical/ReagentUsage/CreateReagentUsage"));
const ViewReagentUsage = React.lazy(() => import("./pages/Chemical/ReagentUsage/ViewReagentUsage"));
const CreateReagentQtyIssuance = React.lazy(() => import("./pages/Chemical/ReagentQtyIssuance/CreateReagentQtyIssuance"));
const ViewReagentQtyIssuance = React.lazy(() => import("./pages/Chemical/ReagentQtyIssuance/ViewReagentQtyIssuance"));
const CreateReagentDestruction = React.lazy(() => import("./pages/Chemical/ReagentDestruction/CreateReagentDestruction"));
const ViewReagentDestruction = React.lazy(() => import("./pages/Chemical/ReagentDestruction/ViewReagentDestruction"));
const ReagentStockCardReport = React.lazy(() => import("./pages/Chemical/Reports/ReagentStockCardReport"));



/* MATERIAL */
const CreateName = React.lazy(() => import("./pages/Material/Name/CreateName"));
const ViewName = React.lazy(() => import("./pages/Material/Name/ViewName"));
const CreateInward = React.lazy(() => import("./pages/Material/Inward/CreateInward"));
const ViewInward = React.lazy(() => import("./pages/Material/Inward/ViewInward"));
const CreateUsage = React.lazy(() => import("./pages/Material/Usage/CreateUsage"));
const ViewUsage = React.lazy(() => import("./pages/Material/Usage/ViewUsage"));
const CreateQtyIssuance = React.lazy(() => import("./pages/Material/QtyIssuance/CreateQtyIssuance"));
const ViewQtyIssuance = React.lazy(() => import("./pages/Material/QtyIssuance/ViewQtyIssuance"));
const CreateDestruction = React.lazy(() => import("./pages/Material/Destruction/CreateDestruction"));
const ViewDestruction = React.lazy(() => import("./pages/Material/Destruction/ViewDestruction"));
const CreateType = React.lazy(() => import("./pages/Material/Type/CreateType"));
const ViewType = React.lazy(() => import("./pages/Material/Type/ViewType"));
const CreateRequest = React.lazy(() => import("./pages/Material/Request/CreateRequest"));
const ViewRequest = React.lazy(() => import("./pages/Material/Request/ViewRequest"));
const MaterialStockCardReport = React.lazy(() => import("./pages/Material/Reports/MaterialStockCardReport"));

/* Environmental Monitoring */
const CreateGrade = React.lazy(() => import("./pages/EnvironmentalMonitoring/Grade/CreateGrade"));
const ViewGrade = React.lazy(() => import("./pages/EnvironmentalMonitoring/Grade/ViewGrade"));
const CreateMonitoringLocation = React.lazy(() => import("./pages/EnvironmentalMonitoring/MonitoringLocation/Create"));
const ViewMonitoringLocation = React.lazy(() => import("./pages/EnvironmentalMonitoring/MonitoringLocation/View"));
const CreateMonitoringSchedule = React.lazy(() => import("./pages/EnvironmentalMonitoring/MonitoringSchedule/Create"));
const ViewMonitoringSchedule = React.lazy(() => import("./pages/EnvironmentalMonitoring/MonitoringSchedule/View"));
const CreateAnalysisInprocess = React.lazy(() => import("./pages/EnvironmentalMonitoring/AnalysisInprocess/Create"));
const CreateaAcknowledgment = React.lazy(() => import("./pages/EnvironmentalMonitoring/Acknowledgment/Create"));
const ViewSampleforAnalysis = React.lazy(() => import("./pages/EnvironmentalMonitoring/Execution/View"));
const EnvApprovalView = React.lazy(() => import("./pages/EnvironmentalMonitoring/Approval/View"));
const EmGeneratedView = React.lazy(() => import("./pages/EnvironmentalMonitoring/EmGenerated/View"));
const EmCoaView = React.lazy(() => import("./pages/EnvironmentalMonitoring/COA/View"));
const CreateScheduleHoldRelease = React.lazy(() => import("./pages/EnvironmentalMonitoring/ScheduleHoldRelease/Create"));
const ViewScheduleHoldRelease = React.lazy(() => import("./pages/EnvironmentalMonitoring/ScheduleHoldRelease/View"));
const ViewNotPerform = React.lazy(() => import("./pages/EnvironmentalMonitoring/EmNotPerform/View"));

/* Working Standards */
const CreateWSInward = React.lazy(() => import("./pages/WorkingStandards/WSInward/Create"));
const ViewWSInward = React.lazy(() => import("./pages/WorkingStandards/WSInward/View"));
const CreateWSIssuance = React.lazy(() => import("./pages/WorkingStandards/WSIssuance/Create"));
const ViewWSIssuance = React.lazy(() => import("./pages/WorkingStandards/WSIssuance/View"));
const CreateWSUsage = React.lazy(() => import("./pages/WorkingStandards/WSUsage/Create"));
const ViewWSUsage = React.lazy(() => import("./pages/WorkingStandards/WSUsage/View"));
const CreateWSDestruction = React.lazy(() => import("./pages/WorkingStandards/WSDestruction/Create"));
const ViewWSDestruction = React.lazy(() => import("./pages/WorkingStandards/WSDestruction/View"));
const WorkingStandardStockCardReport = React.lazy(() => import("./pages/WorkingStandards/Reports/WorkingStandardStockCardReport"));

/* REFERENCE_STANDARDS */
const RsCreationCreate = React.lazy(() => import("./pages/ReferenceStandards/ref_std_creation/Create"));
const RsCreationView = React.lazy(() => import("./pages/ReferenceStandards/ref_std_creation/View"));
const RsInwardCreate = React.lazy(() => import("./pages/ReferenceStandards/ref_std_inward/Create"));
const RsInwardView = React.lazy(() => import("./pages/ReferenceStandards/ref_std_inward/View"));
const RsIssuanceCreate = React.lazy(() => import("./pages/ReferenceStandards/ref_std_issuance/Create"));
const RsIssuanceView = React.lazy(() => import("./pages/ReferenceStandards/ref_std_issuance/View"));
const RsUsageCreate = React.lazy(() => import("./pages/ReferenceStandards/ref_std_usage/Create"));
const RsUsageView = React.lazy(() => import("./pages/ReferenceStandards/ref_std_usage/View"));
const RsDestructionCreate = React.lazy(() => import("./pages/ReferenceStandards/ref_std_destruction/Create"));
const RsDestructionView = React.lazy(() => import("./pages/ReferenceStandards/ref_std_destruction/View"));
const ReferenceStandardStockCardReport = React.lazy(() => import("./pages/ReferenceStandards/Reports/ReferenceStandardStockCardReport"));

/* SAMPLE_MANAGEMENT */
const AnalysisRequestCreate = React.lazy(() => import("./pages/SampleManagement/AnalysisRequest/Create"));
const AnalysisRequestView = React.lazy(() => import("./pages/SampleManagement/AnalysisRequest/View"));
const SamplingCreate = React.lazy(() => import("./pages/SampleManagement/Sampling/Create"));
const SamplingView = React.lazy(() => import("./pages/SampleManagement/Sampling/View"));
const AcknowledgementCreate = React.lazy(() => import("./pages/SampleManagement/Acknowledgement/Create"));
const AcknowledgementView = React.lazy(() => import("./pages/SampleManagement/Acknowledgement/View"));
const StorageCreate = React.lazy(() => import("./pages/SampleManagement/Storage/Create"));
const StorageView = React.lazy(() => import("./pages/SampleManagement/Storage/View"));
const AssignmentCreate = React.lazy(() => import("./pages/SampleManagement/AnalysisAssignment/Create"));
const AssignmentView = React.lazy(() => import("./pages/SampleManagement/AnalysisAssignment/View"));
const AliquotCreate = React.lazy(() => import("./pages/SampleManagement/SampleAliquot/Create"));
const AliquotView = React.lazy(() => import("./pages/SampleManagement/SampleAliquot/View"));
const OutsourceView = React.lazy(() => import("./pages/SampleManagement/OutsourceAnalysis/View"));
const ExecutionView = React.lazy(() => import("./pages/SampleManagement/Execution/View"));
const ApprovalView = React.lazy(() => import("./pages/SampleManagement/Approval/View"));
const DestructionCreate = React.lazy(() => import("./pages/SampleManagement/Destruction/Create"));
const DestructionView = React.lazy(() => import("./pages/SampleManagement/Destruction/View"));
const ViewCOA = React.lazy(() => import("./pages/SampleManagement/COA/View"));
const CreateDerivedCOA = React.lazy(() => import("./pages/SampleManagement/DerivedCOA/Create"));
const ViewDerivedCOA = React.lazy(() => import("./pages/SampleManagement/DerivedCOA/View"));
const CreateCompiledCOA = React.lazy(() => import("./pages/SampleManagement/CompiledCOA/Create"));
const ViewCompiledCOA = React.lazy(() => import("./pages/SampleManagement/CompiledCOA/View"));
const StatusReport = React.lazy(() => import("./pages/SampleManagement/Reports/StatusReport"));
const SampleHistoryReport = React.lazy(() => import("./pages/SampleManagement/Reports/SampleHistoryReport"));
const SampleAnalysisReport = React.lazy(() => import("./pages/SampleManagement/Reports/SampleAnalysisReport"));
const ViewAnalysisReport = React.lazy(() => import("./pages/SampleManagement/Reports/ViewAnalysisReport"));

/* INVESTIGATION */
const InvestigationDetailView = React.lazy(() => import("./pages/Investigation/InvestigationDetailView"));
const InvestigationTestAnalysis = React.lazy(() => import("./pages/Investigation/InvestigationTestAnalysis"));

/* STABILITY_MANAGEMENT */
const ProtocolTemplateCreate = React.lazy(() => import("./pages/StabilityManagement/ProtocolTemplate/Create"));
const ProtocolTemplateView = React.lazy(() => import("./pages/StabilityManagement/ProtocolTemplate/View"));
const ProtocolCreate = React.lazy(() => import("./pages/StabilityManagement/Protocol/Create"));
const ProtocolView = React.lazy(() => import("./pages/StabilityManagement/Protocol/View"));
const StbStorageCreate = React.lazy(() => import("./pages/StabilityManagement/Storage/Create"));
const StbStorageView = React.lazy(() => import("./pages/StabilityManagement/Storage/View"));
const GenerateAR = React.lazy(() => import("./pages/StabilityManagement/PullGeneration/Create"));
//const EditGeneratedSample = React.lazy(() => import("./pages/StabilityManagement/GeneratedSamples/Edit"));
const ViewGeneratedSample = React.lazy(() => import("./pages/StabilityManagement/GeneratedSamples/View"));
const StbAssignmentCreate = React.lazy(() => import("./pages/StabilityManagement/AnalysisAssignment/Create"));
const StbAssignmentView = React.lazy(() => import("./pages/StabilityManagement/AnalysisAssignment/View"));
const StbAliquotCreate = React.lazy(() => import("./pages/StabilityManagement/Aliquot/Create"));
const StbAliquotView = React.lazy(() => import("./pages/StabilityManagement/Aliquot/View"));
const StbDestructionCreate = React.lazy(() => import("./pages/StabilityManagement/Destruction/Create"));
const StbDestructionView = React.lazy(() => import("./pages/StabilityManagement/Destruction/View"));
const StbExecutionView = React.lazy(() => import("./pages/StabilityManagement/Execution/View"));
const StbOutsourceView = React.lazy(() => import("./pages/StabilityManagement/OutsourceAnalysis/View"));
const StbApprovalView = React.lazy(() => import("./pages/StabilityManagement/Approval/View"));
const StbViewCOA = React.lazy(() => import("./pages/StabilityManagement/COA/View"));
const StbStatusReport = React.lazy(() => import("./pages/StabilityManagement/Reports/StatusReport"));
const CreateGeneratedSample = React.lazy(() => import("./pages/StabilityManagement/GeneratedSamples/Create"));
const StabilitySummaryReport = React.lazy(() => import("./pages/StabilityManagement/Reports/StabilitySummaryReport"));


/* INSTRUMENT MANAGEMENT */
const CreateInstrumentType = React.lazy(() => import("./pages/InstrumentManagement/InstrumentType/Create"));
const ViewInstrumentType = React.lazy(() => import("./pages/InstrumentManagement/InstrumentType/View"));
const CreateInstrument = React.lazy(() => import("./pages/InstrumentManagement/Instrument/Create"));
const ViewInstrument = React.lazy(() => import("./pages/InstrumentManagement/Instrument/View"));
const CreateScheduler = React.lazy(() => import("./pages/InstrumentManagement/Scheduler/Create"));
const ViewScheduler = React.lazy(() => import("./pages/InstrumentManagement/Scheduler/View"));
// const insDue = React.lazy(() => import("./pages/InstrumentManagement/Initiation/Home"));
const InitiationCreate = React.lazy(() => import("./pages/InstrumentManagement/Initiation/Create"));
const InitiationView = React.lazy(() => import("./pages/InstrumentManagement/InstrumentGenerated/View"));
const CreateUnscheduled = React.lazy(() => import("./pages/InstrumentManagement/Unscheduled/Create"));
const CreateIssueReporting = React.lazy(() => import("./pages/InstrumentManagement/IssueReporting/Create"));
const ViewIssueReporting = React.lazy(() => import("./pages/InstrumentManagement/IssueReporting/View"));
const CreateServiceRecord = React.lazy(() => import("./pages/InstrumentManagement/ServiceRecord/Create"));
//const ViewServiceRecord = React.lazy(() => import("./pages/InstrumentManagement/ServiceRecord/View"));
const InsCreateUsage = React.lazy(() => import("./pages/InstrumentManagement/Usage/Create"));
const InsViewUsage = React.lazy(() => import("./pages/InstrumentManagement/Usage/View"));
const InsExecutionView = React.lazy(() => import("./pages/InstrumentManagement/Execution/View"));
const InsApprovalView = React.lazy(() => import("./pages/InstrumentManagement/Approval/View"));
const ExternalExecutionView = React.lazy(() => import("./pages/InstrumentManagement/OutsourceAnalysis/View"));
const CalibrationReport = React.lazy(() => import("./pages/InstrumentManagement/Reports/CalibrationReport"));
const ViewUnscheduled = React.lazy(() => import("./pages/InstrumentManagement/Unscheduled/View"));
const CreateInstrumentConfiguration = React.lazy(() => import("./pages/InstrumentManagement/Configuration/Create"));
const ViewInstrumentConfiguration = React.lazy(() => import("./pages/InstrumentManagement/Configuration/View"));
const CreateSpecificationMapping = React.lazy(() => import("./pages/InstrumentManagement/SpecificationMapping/Create"));
const ViewSpecificationMapping = React.lazy(() => import("./pages/InstrumentManagement/SpecificationMapping/View"));


/* Label Management */
const CreateLabelStock = React.lazy(() => import("./pages/LabelManagement/LabelStock/Create"));
const ViewLabelStock = React.lazy(() => import("./pages/LabelManagement/LabelStock/View"));
const CreateLabelDataEntry = React.lazy(() => import("./pages/LabelManagement/LabelDataEntry/Create"));
const ViewLabelDataEntry = React.lazy(() => import("./pages/LabelManagement/LabelDataEntry/View"));
const CreateLabelTemplateDesign = React.lazy(() => import("./pages/LabelManagement/LabelTemplateDesign/Create"));
const ViewLabelTemplateDesign = React.lazy(() => import("./pages/LabelManagement/LabelTemplateDesign/View"));
const CreateLabelPrint = React.lazy(() => import("./pages/LabelManagement/LabelPrint/Create"));
const CreateLabelRePrint = React.lazy(() => import("./pages/LabelManagement/LabelRePrint/Create"));
const LabelStockReport = React.lazy(() => import("./pages/LabelManagement/Reports/LabelStockReport"));
const ViewLabelReprint = React.lazy(() => import("./pages/LabelManagement/LabelRePrint/View"))

/* COLUMN_MANAGEMENT */
const CreateColumnType = React.lazy(() => import("./pages/ColumnManagement/ColumnType/Create"));
const ViewColumnType = React.lazy(() => import("./pages/ColumnManagement/ColumnType/View"));
const CreateColumnCategory = React.lazy(() => import("./pages/ColumnManagement/ColumnCategory/Create"));
const ViewColumnCategory = React.lazy(() => import("./pages/ColumnManagement/ColumnCategory/View"));
const CreateColumnRegistration = React.lazy(() => import("./pages/ColumnManagement/ColumnRegistration/Create"));
const ViewColumnRegistration = React.lazy(() => import("./pages/ColumnManagement/ColumnRegistration/View"));
const CreateColumnMapping = React.lazy(() => import("./pages/ColumnManagement/ColumnMapping/Create"));
const ViewColumnMapping = React.lazy(() => import("./pages/ColumnManagement/ColumnMapping/View"));
const CreateColumnBulkMapping = React.lazy(() => import("./pages/ColumnManagement/ColumnBulkMapping/Create"));
const ViewColumnBulkMapping = React.lazy(() => import("./pages/ColumnManagement/ColumnBulkMapping/View"));
const CreateColumnUsage = React.lazy(() => import("./pages/ColumnManagement/ColumnUsage/Create"));
const ViewColumnUsage = React.lazy(() => import("./pages/ColumnManagement/ColumnUsage/View"));
const CreateColumnQualification = React.lazy(() => import("./pages/ColumnManagement/ColumnQualification/Create"));
const ViewColumnQualification = React.lazy(() => import("./pages/ColumnManagement/ColumnQualification/View"));
const CreateColumnTerminate = React.lazy(() => import("./pages/ColumnManagement/ColumnTerminate/Create"));
const ViewColumnTerminate = React.lazy(() => import("./pages/ColumnManagement/ColumnTerminate/View"));

/* RETAIN_SAMPLE */
const RetInwardCreate = React.lazy(() => import("./pages/RetainSample/Inward/Create"));
const RetRequestCreate = React.lazy(() => import("./pages/RetainSample/Request/Create"));
const RetDestructionCreate = React.lazy(() => import("./pages/RetainSample/Destruction/Create"));
//const retVIDue = React.lazy(() => import("./pages/RetainSample/Inspection-Due/Home"));
const retSchedulerCreate = React.lazy(() => import("./pages/RetainSample/Inspection-Schedular/Create"));
const retDueCreate = React.lazy(() => import("./pages/RetainSample/Inspection-Due/Create"));
const retAssignmentView = React.lazy(() => import("./pages/RetainSample/Inspection-Assignment/View"));
const retVIChecklistCreate = React.lazy(() => import("./pages/RetainSample/Inspection-Checklist/Create"));
const RetDestructionView = React.lazy(() => import("./pages/RetainSample/Destruction/View"));
const RetInwardView = React.lazy(() => import("./pages/RetainSample/Inward/View"));
const RetRequestView = React.lazy(() => import("./pages/RetainSample/Request/View"));
const RetSchedulerView = React.lazy(() => import("./pages/RetainSample/Inspection-Schedular/View"));
const RetChecklistView = React.lazy(() => import("./pages/RetainSample/Inspection-Checklist/View"));
const retExecutionCreate = React.lazy(() => import("./pages/RetainSample/Inspection-Execution/Create"));
const retGeneratedView = React.lazy(() => import("./pages/RetainSample/RetainGenerated/View"));
const retAssignmentcreate = React.lazy(() => import("./pages/RetainSample/Inspection-Assignment/Create"));

/* Solution Management */
const SolCreateSolution = React.lazy(() => import("./pages/SolutionManagement/Solution/Create"));
const SolViewSolution = React.lazy(() => import("./pages/SolutionManagement/Solution/View"));
const SolCreatePreparation = React.lazy(() => import("./pages/SolutionManagement/Preparation/Create"));
const SolViewPreparation = React.lazy(() => import("./pages/SolutionManagement/Preparation/View"));
const SolCreateAr = React.lazy(() => import("./pages/SolutionManagement/AnalysisRequest/Create"));
const SolViewAr = React.lazy(() => import("./pages/SolutionManagement/AnalysisRequest/View"));
const SolCreateAssignment = React.lazy(() => import("./pages/SolutionManagement/Assignment/Create"));
const SolCreateUsage = React.lazy(() => import("./pages/SolutionManagement/Usage/Create"));
const SolViewUsage = React.lazy(() => import("./pages/SolutionManagement/Usage/View"));
const SolExecutionView = React.lazy(() => import("./pages/SolutionManagement/Execution/View"));
const SolUsageLogReport = React.lazy(() => import("./pages/SolutionManagement/Reports/SolUsageLogReport"));
const SolApprovalView = React.lazy(() => import("./pages/SolutionManagement/Approval/View"));

/* Volumetric Solution Management */
const CreateVolumetricSolution = React.lazy(() => import("./pages/VolumetricSolution/Solution/Create"));
const ViewVolumetricSolution = React.lazy(() => import("./pages/VolumetricSolution/Solution/View"));
const CreateVolumetricPreparation = React.lazy(() => import("./pages/VolumetricSolution/Preparation/Create"));
const ViewVolumetricPreparation = React.lazy(() => import("./pages/VolumetricSolution/Preparation/View"));
const CreateInitation = React.lazy(() => import("./pages/VolumetricSolution/Initation/Create"));
const ViewInitation = React.lazy(() => import("./pages/VolumetricSolution/Initation/View"));
const VolCreateUsage = React.lazy(() => import("./pages/VolumetricSolution/Usage/Create"));
const VolViewUsage = React.lazy(() => import("./pages/VolumetricSolution/Usage/View"));
const VolExecutionView = React.lazy(() => import("./pages/VolumetricSolution/Execution/View"));
const VolApprovalView = React.lazy(() => import("./pages/VolumetricSolution/Approval/View"));
const VolSolUsageLogReport = React.lazy(() => import("./pages/VolumetricSolution/Reports/VolSolUsageLogReport"));
const CreateStandardizationInitation = React.lazy(() => import("./pages/VolumetricSolution/StandardizationInitation/Create"));
const ViewStandardizationInitation = React.lazy(() => import("./pages/VolumetricSolution/StandardizationInitation/View"));
const StandardizationExecutionView = React.lazy(() => import("./pages/VolumetricSolution/StandardizationExecution/View"));
const StandardizationApprovalView = React.lazy(() => import("./pages/VolumetricSolution/StandardizationApproval/View"));

/* LDMS */
const CreateDocumentType = React.lazy(() => import("./pages/LDMS/DocumentType/CreateDocumentType"));
const ViewDocumentType = React.lazy(() => import("./pages/LDMS/DocumentType/ViewDocumentType"));
const CreateDocumentUpload = React.lazy(() => import("./pages/LDMS/DocumentUpload/CreateDocumentUpload"));
const ViewDocumentUpload = React.lazy(() => import("./pages/LDMS/DocumentUpload/ViewDocumentUpload"));
const CreateDocumentMapping = React.lazy(() => import("./pages/LDMS/DocumentMapping/CreateDocumentMapping"));
const ViewDocumentMapping = React.lazy(() => import("./pages/LDMS/DocumentMapping/ViewDocumentMapping"));
const CreateDocumentArchival = React.lazy(() => import("./pages/LDMS/DocumentArchival/CreateDocumentArchival"));
const ViewDocumentArchival = React.lazy(() => import("./pages/LDMS/DocumentArchival/ViewDocumentArchival"));

/* ANALYST_QUALIFICATION */
const CreateAQConfiguration = React.lazy(() => import("./pages/AnalystQualification/Configuration/Create"));
const ViewAQConfiguration = React.lazy(() => import("./pages/AnalystQualification/Configuration/View"));
const CreateAQInitiated = React.lazy(() => import("./pages/AnalystQualification/AnalystInitiation/Initiated/Create"));
const ViewAQInitiated = React.lazy(() => import("./pages/AnalystQualification/AnalystInitiation/Initiated/View"));
const ViewAQRQPending = React.lazy(() => import("./pages/AnalystQualification/AnalystInitiation/RQPending/View"));
const ViewAQRQInitiated = React.lazy(() => import("./pages/AnalystQualification/AnalystInitiation/RQInitiated/View"));
const AQExecution = React.lazy(() => import("./pages/AnalystQualification/Execution/View"));
const CreateAQEAInitiation = React.lazy(() => import("./pages/AnalystQualification/ExpertAnalysisInitiation/Create"));
const ViewAQEAInitiation = React.lazy(() => import("./pages/AnalystQualification/ExpertAnalysisInitiation/View"));
const AQEAExecution = React.lazy(() => import("./pages/AnalystQualification/ExpertAnalystExecution/View"));
const AQApprovalView = React.lazy(() => import("./pages/AnalystQualification/Approval/View"));
const AQViewCertificate = React.lazy(() => import("./pages/AnalystQualification/Certificate/View"));
const AQCreateDirectqualification = React.lazy(() => import("./pages/AnalystQualification/Direct_qualification/Create"));
const AQVieweDirectqualification = React.lazy(() => import("./pages/AnalystQualification/Direct_qualification/View"));

/* Common Reports */
const RawDataReport = React.lazy(() => import("./pages/Reports/RawData"));

/* Media Managment */
const CreateMediaName = React.lazy(() => import("./pages/Media/Name/Create"));
const ViewMediaName = React.lazy(() => import("./pages/Media/Name/View"));
const CreateMediaPreparation = React.lazy(() => import("./pages/Media/Preparation/Create"));
const ViewMediaPreparation = React.lazy(() => import("./pages/Media/Preparation/View"));
const CreateMediaInward = React.lazy(() => import("./pages/Media/Inward/Create"));
const ViewMediaInward = React.lazy(() => import("./pages/Media/Inward/View"));
const TokenValidator = React.lazy(() => import("./components/TokenValidator"));
const MediaExecutionView = React.lazy(() => import("./pages/Media/Execution/View"));
const MediaApprovalView = React.lazy(() => import("./pages/Media/Approval/View"));
const CreateMediaAcknowledgment = React.lazy(() => import("./pages/Media/Acknowledgment/Create"));
const MediaAnalysisView = React.lazy(() => import("./pages/Media/InwardAnalysis/View"));
const MediaInwardApprovalView = React.lazy(() => import("./pages/Media/InwardApproval/View"));
const CreateMediaIssuance = React.lazy(() => import("./pages/Media/Issance/Create"));
const ViewMediaIssuance = React.lazy(() => import("./pages/Media/Issance/View"));
const CreateMediaUsage = React.lazy(() => import("./pages/Media/Usage/CreateUsage"));
const ViewMediaUsage = React.lazy(() => import("./pages/Media/Usage/ViewUsage"));
const CreateMediaDestruction = React.lazy(() => import("./pages/Media/Destruction/CreateDestruction"));
const ViewMediaDestruction = React.lazy(() => import("./pages/Media/Destruction/ViewDestruction"));

/* Culture Managment */
const CreateCultureTemplate = React.lazy(() => import("./pages/CultureManagement/CultureTemplate/Create"));
const ViewCultureTemplate = React.lazy(() => import("./pages/CultureManagement/CultureTemplate/View"));
const CreateCultureCreation = React.lazy(() => import("./pages/CultureManagement/CultureCreation/Create"));
const ViewCultureCreation = React.lazy(() => import("./pages/CultureManagement/CultureCreation/View"));
const CreateCultureInward = React.lazy(() => import("./pages/CultureManagement/CultureInward/Create"));
const ViewCultureInward = React.lazy(() => import("./pages/CultureManagement/CultureInward/View"));
const CreateCultureAcknowledgment = React.lazy(() => import("./pages/CultureManagement/Acknowledgment/Create"));
const ViewCultureInwardAnalysis = React.lazy(() => import("./pages/CultureManagement/InwardAnalysis/View"));
const ViewCultureInwardApproval = React.lazy(() => import("./pages/CultureManagement/InwardApproval/View"));
const CreateCultureGeneration = React.lazy(() => import("./pages/CultureManagement/CultureGeneration/CreateGeneration"));
const ViewCultureGeneration = React.lazy(() => import("./pages/CultureManagement/CultureGeneration/ViewGeneration"));
const GenerateCultureAr = React.lazy(() => import("./pages/CultureManagement/CultureGeneration/GenerateAr"));
const ViewCultureAnalysis = React.lazy(() => import("./pages/CultureManagement/Analysis/View"));
const ViewCultureApproval = React.lazy(() => import("./pages/CultureManagement/Approval/View"));
const ViewGeneration = React.lazy(() => import("./pages/CultureManagement/CultureGenerated/ViewGeneration"));
const ViewGeneratiSubmition = React.lazy(() => import("./pages/CultureManagement/CultureGenerated/ViewGeneratiSubmition"));
const CreateCultureUsage = React.lazy(() => import("./pages/CultureManagement/CultureUsage/CreateUsage"));
const ViewCultureUsage = React.lazy(() => import("./pages/CultureManagement/CultureUsage/ViewUsage"));
const CultureGenerationLogReport = React.lazy(() => import("./pages/CultureManagement/Reports/CultureGenerationLogReport"));

const App = () => (
  <MemoryRouter>
    <ErrorBoundary>
      <Provider store={reduxStore}>
        <Suspense fallback={<Loading />}>
          <TokenValidator />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/account-activate" component={AccountActivate} />
            <Route exact path="/logout" component={Login} />
            <Route exact path="/lego/:module_name/:resource_path" component={Grid} />
            <Route exact path="/lego-report-grid/:module_name/:resource_path" component={ReportGrid} />
            <Route exact path="/lego-test-trend-report-grid/:menu_id/:resource_path" component={TestTrendReport} />
            <Route exact path="/v-history/:id/:resource_path/:menu_id" component={ViewHistory} />
            <Route exact path="/test-data" component={TestData} />

            {/* ADMINSTRATION */}
            <Route exact path="/c-sites/:id/:ref" component={CreateSite} />
            <Route exact path="/v-sites/:id/:ref" component={ViewSite} />
            <Route exact path="/security-settings" component={SecuritySettings} />
            <Route exact path="/security-settings/:id/:ref" component={SecuritySettings} />
            <Route exact path="/app-configs" component={AppConfigurations} />
            <Route exact path="/c-users/:id/:ref" component={CreateUser} />
            <Route exact path="/v-users/:id/:ref" component={ViewUser} />
            <Route exact path="/c-groups/:id/:ref" component={CreateGroup} />
            <Route exact path="/v-groups/:id/:ref" component={ViewGroup} />
            <Route exact path="/license" component={License} />
            <Route exact path="/c-user-types/:id/:ref" component={CreateUserType} />
            <Route exact path="/v-user-types/:id/:ref" component={ViewUserType} />
            <Route exact path="/c-default-reasons/:id/:ref" component={CreateRemark} />
            <Route exact path="/v-default-reasons/:id/:ref" component={ViewRemark} />
            <Route exact path="/c-uom/:id/:ref" component={CreateUnit} />
            <Route exact path="/v-uom/:id/:ref" component={ViewUnit} />
            <Route exact path="/c-reason-code/:id/:ref" component={CreateReasonCode} />
            <Route exact path="/v-reason-code/:id/:ref" component={ViewReasonCode} />
            <Route exact path="/e-signature" component={SignatureConfiguration} />
            <Route exact path="/c-approval-config/:id/:ref" component={CreateApproval} />
            <Route exact path="/v-approval-config/:id/:ref" component={ViewApproval} />

            {/* MASTERS */}
            <Route exact path="/c-analysis-type/:id/:ref" component={CreateAnalysisType} />
            <Route exact path="/v-analysis-type/:id/:ref" component={ViewAnalysisType} />
            <Route exact path="/c-investigation-checklists/:id/:ref" component={CreateInvestigationChecklist} />
            <Route exact path="/v-investigation-checklists/:id/:ref" component={ViewInvestigationChecklist} />
            <Route exact path="/c-sampling-checklist/:id/:ref" component={CreateSamplingChecklist} />
            <Route exact path="/v-sampling-checklist/:id/:ref" component={ViewSamplingChecklist} />
            <Route exact path="/c-shared-list/:id/:ref" component={CreateSharedList} />
            <Route exact path="/v-shared-list/:id/:ref" component={ViewSharedList} />
            <Route exact path="/c-custom-fields/:id/:ref" component={CreateCustomFields} />
            <Route exact path="/v-custom-fields/:id/:ref" component={ViewCustomFields} />
            <Route exact path="/c-test-variables/:id/:ref" component={CreateTestVariables} />
            <Route exact path="/v-test-variables/:id/:ref" component={ViewTestVariable} />
            <Route exact path="/c-coa-template/:id/:ref" component={CreateCOATemplate} />
            <Route exact path="/v-coa-template/:id/:ref" component={ViewCOATemplate} />
            <Route exact path="/c-datasheet-template/:id/:ref" component={CreateDatasheetTemplate} />
            <Route exact path="/v-datasheet-template/:id/:ref" component={ViewDatasheetTemplate} />
            <Route exact path="/c-test-registration/:id/:ref" component={CreateTest} />
            <Route exact path="/test-registration/config/:sub_test_id/:step_id/:ref/:pref" component={ConfigureTest} />
            <Route exact path="/v-test-registration/:id/:ref" component={ViewTest} />
            <Route exact path="/c-datasheet/:id/:ref" component={CreateDatasheet} />
            <Route exact path="/v-datasheet/:id/:ref" component={ViewDatasheet} />
            <Route exact path="/c-specification/:id/:ref" component={CreateSpecification} />
            <Route exact path="/v-specification/:id/:ref" component={ViewSpecification} />
            <Route exact path="/c-storage-condition/:id/:ref" component={CreateCondition} />
            <Route exact path="/v-storage-condition/:id/:ref" component={ViewCondition} />
            <Route exact path="/c-customers/:id/:ref" component={CreateCustomer} />
            <Route exact path="/v-customers/:id/:ref" component={ViewCustomer} />
            <Route exact path="/c-approved-supplier-manufacturer/:id/:ref" component={CreateApprovedSupplier} />
            <Route exact path="/v-approved-supplier-manufacturer/:id/:ref" component={ViewApprovedSupplier} />
            <Route exact path="/c-projects/:id/:ref" component={CreateProject} />
            <Route exact path="/v-projects/:id/:ref" component={ViewProject} />
            <Route exact path="/c-supplier-manufacturers/:id/:ref" component={CreateSupplierManufacturers} />
            <Route exact path="/v-supplier-manufacturers/:id/:ref" component={ViewSupplierManufacturers} />
            <Route exact path="/create-custom-text-fields/:id/:ref" component={CustomFieldsTextBox} />
            <Route exact path="/c-product-materials/:id/:ref" component={CreateProductMaterial} />
            <Route exact path="/v-product-materials/:id/:ref" component={ViewProductMaterials} />
            <Route exact path="/c-sample-type/:id/:ref" component={CreateSampleType} />
            <Route exact path="/v-sample-type/:id/:ref" component={ViewSampleType} />
            <Route exact path="/c-storage-buildings/:id/:ref" component={CreateBuilding} />
            <Route exact path="/c-storage-department/:id/:ref" component={CreateDepartment} />
            <Route exact path="/c-storage-room/:id/:ref" component={CreateRoom} />
            <Route exact path="/c-storage-device/:id/:ref" component={CreateStorageDevice} />
            <Route exact path="/c-storage-location/:id/:ref" component={CreateLocation} />
            <Route exact path="/v-storage-buildings/:id/:ref" component={ViewBuilding} />
            <Route exact path="/v-storage-Location/:id/:ref" component={ViewLocation} />
            <Route exact path="/v-storage-department/:id/:ref" component={ViewDepartment} />
            <Route exact path="/v-storage-device/:id/:ref" component={ViewDevice} />
            <Route exact path="/v-storage-room/:id/:ref" component={ViewRoom} />
            <Route exact path="/c-service-provider/:id/:ref" component={ServiceProviderCreate} />
            <Route exact path="/v-service-provider/:id/:ref" component={ServiceProviderView} />
            <Route exact path="/master_specification_report/:id/:ref" component={SpecificationReport} />
            <Route exact path="/master_storage_location_report/:id/:ref" component={StorageLocationReport} />

            {/* CHEMICAL */}
            <Route exact path="/c-chem-name/:id/:ref" component={CreateReagentName} />
            <Route exact path="/v-chem-name/:id/:ref" component={ViewReagentName} />
            <Route exact path="/c-chem-inward/:id/:ref" component={CreateReagentInward} />
            <Route exact path="/v-chem-inward/:id/:ref" component={ViewReagentInward} />
            <Route exact path="/c-chem-qty-issuance/:id/:ref" component={CreateReagentQtyIssuance} />
            <Route exact path="/v-chem-qty-issuance/:id/:ref" component={ViewReagentQtyIssuance} />
            <Route exact path="/c-chem-used/:id/:ref" component={CreateReagentUsage} />
            <Route exact path="/v-chem-used/:id/:ref" component={ViewReagentUsage} />
            <Route exact path="/c-chem-destruction/:id/:ref" component={CreateReagentDestruction} />
            <Route exact path="/v-chem-destruction/:id/:ref" component={ViewReagentDestruction} />
            <Route exact path="/chm-stockcard-report/:id/:ref" component={ReagentStockCardReport} />
            <Route exact path="/v-chm-usage-report/:id/:ref" component={ViewReagentUsage} />
            

            {/* MATERIAL_MANAGEMENT */}
            <Route exact path="/c-material-type/:id/:ref" component={CreateType} />
            <Route exact path="/v-material-type/:id/:ref" component={ViewType} />
            <Route exact path="/c-material-name/:id/:ref" component={CreateName} />
            <Route exact path="/v-material-name/:id/:ref" component={ViewName} />
            <Route exact path="/c-material-inward/:id/:ref" component={CreateInward} />
            <Route exact path="/v-material-inward/:id/:ref" component={ViewInward} />
            <Route exact path="/c-material-request/:id/:ref" component={CreateRequest} />
            <Route exact path="/v-material-request/:id/:ref" component={ViewRequest} />
            <Route exact path="/c-material-issuances/:id/:ref" component={CreateQtyIssuance} />
            <Route exact path="/v-material-issuances/:id/:ref" component={ViewQtyIssuance} />
            <Route exact path="/c-material-usages/:id/:ref" component={CreateUsage} />
            <Route exact path="/v-material-usages/:id/:ref" component={ViewUsage} />
            <Route exact path="/c-material-destruction/:id/:ref" component={CreateDestruction} />
            <Route exact path="/v-material-destruction/:id/:ref" component={ViewDestruction} />
            <Route exact path="/mat_stockcard_report/:id/:ref" component={MaterialStockCardReport} />

            {/*Working Standards*/}
            <Route exact path="/c-working-standard-inward/:id/:ref" component={CreateWSInward} />
            <Route exact path="/v-working-standard-inward/:id/:ref" component={ViewWSInward} />
            <Route exact path="/c-working-standard-issuance/:id/:ref" component={CreateWSIssuance} />
            <Route exact path="/v-working-standard-issuance/:id/:ref" component={ViewWSIssuance} />
            <Route exact path="/c-working-standard-usage/:id/:ref" component={CreateWSUsage} />
            <Route exact path="/v-working-standard-usage/:id/:ref" component={ViewWSUsage} />
            <Route exact path="/c-working-standard-destruction/:id/:ref" component={CreateWSDestruction} />
            <Route exact path="/v-working-standard-destruction/:id/:ref" component={ViewWSDestruction} />
            <Route exact path="/wks_stockcard_report/:id/:ref" component={WorkingStandardStockCardReport} />
            <Route exact path="/v-working-standard-usage-report/:id/:ref" component={ViewWSUsage} />

            {/* REFERENCE_STANDARDS */}
            <Route exact path="/c-reference-standard-creation/:id/:ref" component={RsCreationCreate} />
            <Route exact path="/v-reference-standard-creation/:id/:ref" component={RsCreationView} />
            <Route exact path="/c-reference-standard-inward/:id/:ref" component={RsInwardCreate} />
            <Route exact path="/v-reference-standard-inward/:id/:ref" component={RsInwardView} />
            <Route exact path="/c-reference-standard-issuance/:id/:ref" component={RsIssuanceCreate} />
            <Route exact path="/v-reference-standard-issuance/:id/:ref" component={RsIssuanceView} />
            <Route exact path="/c-reference-standard-usage/:id/:ref" component={RsUsageCreate} />
            <Route exact path="/v-reference-standard-usage/:id/:ref" component={RsUsageView} />
            <Route exact path="/c-reference-standard-destruction/:id/:ref" component={RsDestructionCreate} />
            <Route exact path="/v-reference-standard-destruction/:id/:ref" component={RsDestructionView} />
            <Route exact path="/ref_stockcard_report/:id/:ref" component={ReferenceStandardStockCardReport} />

            {/* LIMS_EXPERIMENT_MANAGEMENT */}
            <Route exact path="/c-section/:id/:ref" component={CreateSection} />
            <Route exact path="/v-section/:id/:ref" component={ViewSection} />
            <Route exact path="/c-chemical-structure/:id/:ref" component={CreateChemicalStructure} />
            <Route exact path="/v-chemical-structure/:id/:ref" component={ViewChemicalStructure} />
            <Route exact path="/c-template/:id/:ref" component={CreateTemplate} />
            <Route exact path="/v-template/:id/:ref" component={ViewTemplate} />
            <Route exact path="/c-experiment-creation/:id/:ref" component={CreateExperiment} />
            <Route exact path="/v-experiment-creation/:id/:ref" component={ViewExperiment} />
            <Route exact path="/v-my-experiment/:id/:ref" component={ViewMyExperiment} />
            <Route exact path="/v-experiment-approval/:id/:ref" component={ViewExperimentApproval} />
            <Route exact path="/v-released-experiment/:id/:ref" component={ViewExperimentReleased} />
            <Route exact path="/v-experiment-report/:id/:ref" component={ViewExperimentReport} />

            {/* SAMPLE_MANAGEMENT */}
            <Route exact path="/c-analysis-request/:id/:ref" component={AnalysisRequestCreate} />
            <Route exact path="/v-analysis-request/:id/:ref" component={AnalysisRequestView} />
            <Route exact path="/c-sampling/:id/:ref" component={SamplingCreate} />
            <Route exact path="/v-sampling/:id/:ref" component={SamplingView} />
            <Route exact path="/c-sample-acknowledgment/:id/:ref" component={AcknowledgementCreate} />
            <Route exact path="/v-sample-acknowledgment/:id/:ref" component={AcknowledgementView} />
            <Route exact path="/c-sample-storage/:id/:ref" component={StorageCreate} />
            <Route exact path="/v-sample-storage/:id/:ref" component={StorageView} />
            <Route exact path="/c-analysis-assignment/:id/:ref" component={AssignmentCreate} />
            <Route exact path="/v-analysis-assignment/:id/:ref" component={AssignmentView} />
            <Route exact path="/c-sample-aliquot/:id/:ref" component={AliquotCreate} />
            <Route exact path="/v-sample-aliquot/:id/:ref" component={AliquotView} />
            <Route exact path="/v-outsource-analysis/:id/:ref" component={OutsourceView} />
            <Route exact path="/v-samples-for-analysis/:id/:ref" component={ExecutionView} />
            <Route exact path="/v-samples-review-approvals/:id/:ref" component={ApprovalView} />
            <Route exact path="/c-sample-destruction/:id/:ref" component={DestructionCreate} />
            <Route exact path="/v-sample-destruction/:id/:ref" component={DestructionView} />
            <Route exact path="/v-certificate-of-analysis-coa/:id/:ref" component={ViewCOA} />
            <Route exact path="/c-derived-coa/:id/:ref" component={CreateDerivedCOA} />
            <Route exact path="/v-derived-coa/:id/:ref" component={ViewDerivedCOA} />
            <Route exact path="/c-compiled-coa/:id/:ref" component={CreateCompiledCOA} />
            <Route exact path="/v-compiled-coa/:id/:ref" component={ViewCompiledCOA} />
            <Route exact path="/smp-status-by-st-report" component={StatusReport} />
            <Route exact path="/v-smp-history-report/:id/:ref" component={SampleHistoryReport} />
            <Route exact path="/v-smp-raw-data/:menu_id/:id/:ref" component={RawDataReport} />
            <Route exact path="/v-smp-inward-report/:id/:ref" component={AnalysisRequestView} />
            <Route exact path="/v-smp-destruction-report/:id/:ref" component={DestructionView} />
            <Route exact path="/lego-smp-analysis-report/:menu_id/:resource_path" component={SampleAnalysisReport} />
            <Route exact path="/v-smp-analysis-report-view/:id/:ref" component={ViewAnalysisReport} />
            <Route exact path="/v-sample-sampling-log-report/:id/:ref" component={SamplingView} />

            {/* INVESTIGATION */}
            <Route exact path="/v-qc-investigation-level-1/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-qc-investigation-level-2/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-rnd-investigation-level-1/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-rnd-investigation-level-2/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-qa-investigation-review/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-qa-investigation-approve/:menu_id/:id/:ref" component={InvestigationDetailView} />
            <Route exact path="/v-tests-under-investigation/:menu_id/:id/:ref" component={InvestigationTestAnalysis} />

            {/* STABILITY_MANAGEMENT */}
            <Route exact path="/c-protocol-template/:id/:ref" component={ProtocolTemplateCreate} />
            <Route exact path="/v-protocol-template/:id/:ref" component={ProtocolTemplateView} />
            <Route exact path="/c-stb-protocols/:id/:ref" component={ProtocolCreate} />
            <Route exact path="/v-stb-protocols/:id/:ref" component={ProtocolView} />
            <Route exact path="/c-stb-storage/:id/:ref" component={StbStorageCreate} />
            <Route exact path="/v-stb-storage/:id/:ref" component={StbStorageView} />
            <Route exact path="/v-stb-sample-generation/:id/:ref" component={GenerateAR} />
            <Route exact path="/c-stb-generated-samples/:id/:ref" component={CreateGeneratedSample} />
            <Route exact path="/v-stb-generated-samples/:id/:ref" component={ViewGeneratedSample} />
            <Route exact path="/c-stb-analysis-assignment/:id/:ref" component={StbAssignmentCreate} />
            <Route exact path="/v-stb-analysis-assignment/:id/:ref" component={StbAssignmentView} />
            <Route exact path="/c-stb-sample-aliquot/:id/:ref" component={StbAliquotCreate} />
            <Route exact path="/v-stb-sample-aliquot/:id/:ref" component={StbAliquotView} />
            <Route exact path="/c-stb-sample-destruction/:id/:ref" component={StbDestructionCreate} />
            <Route exact path="/v-stb-sample-destruction/:id/:ref" component={StbDestructionView} />
            <Route exact path="/v-stb-sample-analysis/:id/:ref" component={StbExecutionView} />
            <Route exact path="/v-stb-outsource-analysis/:id/:ref" component={StbOutsourceView} />
            <Route exact path="/v-stb-approval/:id/:ref" component={StbApprovalView} />
            <Route exact path="/v-stb-coa/:id/:ref" component={StbViewCOA} />
            <Route exact path="/lego/stb-stbst-report" component={StbStatusReport} />
            <Route exact path="/v-stb-raw-data/:menu_id/:id/:ref" component={RawDataReport} />
            <Route exact path="/stb-summaryreport" component={StabilitySummaryReport} />

            {/* INSTRUMENT MANAGEMENT */}
            <Route exact path="/c-instrument-type/:id/:ref" component={CreateInstrumentType} />
            <Route exact path="/v-instrument-type/:id/:ref" component={ViewInstrumentType} />
            <Route exact path="/c-instrument/:id/:ref" component={CreateInstrument} />
            <Route exact path="/v-instrument/:id/:ref" component={ViewInstrument} />
            <Route exact path="/c-ins-scheduler/:id/:ref" component={CreateScheduler} />
            <Route exact path="/v-ins-scheduler/:id/:ref" component={ViewScheduler} />
            {/* <Route exact path="/instrument-inspection-due" component={insDue} /> */}
            <Route exact path="/v-initiation/:id/:ref" component={InitiationCreate} />
            <Route exact path="/v-calibration-initiated/:id/:ref" component={InitiationView} />
            <Route exact path="/c-unscheduled-calibration/:id/:ref" component={CreateUnscheduled} />
            <Route exact path="/c-breakdown-maintanence-reporting/:id/:ref" component={CreateIssueReporting} />
            <Route exact path="/v-breakdown-maintanence-reporting/:id/:ref" component={ViewIssueReporting} />
            <Route exact path="/v-service-record/:id/:ref" component={CreateServiceRecord} />
            <Route exact path="/c-service-record/:id/:ref" component={CreateServiceRecord} />
            <Route exact path="/c-instrument-usage/:id/:ref" component={InsCreateUsage} />
            <Route exact path="/v-instrument-usage/:id/:ref" component={InsViewUsage} />
            <Route exact path="/v-calibration-execution/:id/:ref" component={InsExecutionView} />
            <Route exact path="/v-calibration-external-execution/:id/:ref" component={ExternalExecutionView} />
            <Route exact path="/v-calibration-approvals/:id/:ref" component={InsApprovalView} />
            <Route exact path="/v-calibration-in-process/:id/:ref" component={InitiationCreate} />
            <Route exact path="/v-calibration-initiated/:id/:ref" component={InitiationCreate} />
            <Route exact path="/calibration_reports/:id/:ref" component={CalibrationReport} />
            <Route exact path="/v-unscheduled-calibration/:id/:ref" component={ViewUnscheduled} />
            <Route exact path="/v-ins-raw-data/:menu_id/:id/:ref" component={RawDataReport} />
            <Route exact path="/c-ins-configuration/:id/:ref" component={CreateInstrumentConfiguration} />
            <Route exact path="/v-ins-configuration/:id/:ref" component={ViewInstrumentConfiguration}/>
            <Route exact path="/c-ins-specification-mapping/:id/:ref" component={CreateSpecificationMapping} />
            <Route exact path="/v-ins-specification-mapping/:id/:ref" component={ViewSpecificationMapping}/>
           
            
            

            {/* COLUMN_MANAGEMENT */}
            <Route exact path="/c-column-type/:id/:ref" component={CreateColumnType} />
            <Route exact path="/v-column-type/:id/:ref" component={ViewColumnType} />
            <Route exact path="/c-column-category/:id/:ref" component={CreateColumnCategory} />
            <Route exact path="/v-column-category/:id/:ref" component={ViewColumnCategory} />
            <Route exact path="/c-column-registration/:id/:ref" component={CreateColumnRegistration} />
            <Route exact path="/v-column-registration/:id/:ref" component={ViewColumnRegistration} />
            <Route exact path="/c-column-mapping/:id/:ref" component={CreateColumnMapping} />
            <Route exact path="/v-column-mapping/:id/:ref" component={ViewColumnMapping} />
            <Route exact path="/c-column-bulk-mapping/:id/:ref" component={CreateColumnBulkMapping} />
            <Route exact path="/v-column-bulk-mapping/:id/:ref" component={ViewColumnBulkMapping} />
            <Route exact path="/c-column-qualification/:id/:ref" component={CreateColumnQualification} />
            <Route exact path="/v-column-qualification/:id/:ref" component={ViewColumnQualification} />
            <Route exact path="/c-column-usage/:id/:ref" component={CreateColumnUsage} />
            <Route exact path="/v-column-usage/:id/:ref" component={ViewColumnUsage} />
            <Route exact path="/c-terminate-column/:id/:ref" component={CreateColumnTerminate} />
            <Route exact path="/v-terminate-column/:id/:ref" component={ViewColumnTerminate} />

            {/* RETAIN_SAMPLE */}
            <Route exact path="/v-ret-vidue-in-process/:id/:ref" component={retDueCreate} />
            <Route exact path="/c-retain-sample-inward/:id/:ref" component={RetInwardCreate} />
            <Route exact path="/v-retain-sample-inward/:id/:ref" component={RetInwardView} />
            <Route exact path="/c-retain-sample-request/:id/:ref" component={RetRequestCreate} />
            <Route exact path="/v-retain-sample-request/:id/:ref" component={RetRequestView} />
            <Route exact path="/c-retain-sample-destruction/:id/:ref" component={RetDestructionCreate} />
            <Route exact path="/v-retain-sample-destruction/:id/:ref" component={RetDestructionView} />
            {/*<Route exact path="/visual-inspection-due" component={retVIDue} />*/}
            {/* <Route exact path="/visual-inspection-execution" component={retVIExecution} /> */}
            <Route exact path="/v-visual-inspection-assignment/:id/:ref" component={retAssignmentView} />
            <Route exact path="/v-visual-inspection-execution/:id/:ref" component={retExecutionCreate} />
            <Route exact path="/c-visual-inspection-execution/:id/:ref" component={retExecutionCreate} />
            <Route exact path="/create-visual-inspection-due/:id/:ref" component={retDueCreate} />
            {/* <Route exact path="/v-visual-inspection-due/:id/:ref" component={retDueCreate} /> */}
            <Route exact path="/create-visual-inspection-due/:id/:ref" component={retDueCreate} />
            <Route exact path="/v-visual-inspection-due/:id/:ref" component={retDueCreate} />
            <Route exact path="/c-visual-inspection-assignment/:id/:ref" component={retAssignmentcreate} />
            <Route exact path="/c-visual-inspection-checklist/:id/:ref" component={retVIChecklistCreate} />
            <Route exact path="/v-visual-inspection-checklist/:id/:ref" component={RetChecklistView} />
            <Route exact path="/c-visual-inspection-schedular/:id/:ref" component={retSchedulerCreate} />
            <Route exact path="/v-visual-inspection-schedular/:id/:ref" component={RetSchedulerView} />
            <Route exact path="/v-due-in-process/:id/:ref" component={retDueCreate} />
            <Route exact path="/v-due-generated/:id/:ref" component={retDueCreate} />

            <Route exact path="/v-assignment/:id/:ref" component={retAssignmentcreate} />
            <Route exact path="/v-ret-vidue-generated/:id/:ref" component={retGeneratedView} />

            {/* Solution Management */}
            <Route exact path="/c-solution-creation/:id/:ref" component={SolCreateSolution} />
            <Route exact path="/v-solution-creation/:id/:ref" component={SolViewSolution} />
            <Route exact path="/c-solution-preparation/:id/:ref" component={SolCreatePreparation} />
            <Route exact path="/v-solution-preparation/:id/:ref" component={SolViewPreparation} />
            <Route exact path="/c-solution-analysis-request/:id/:ref" component={SolCreateAr} />
            <Route exact path="/v-solution-analysis-request/:id/:ref" component={SolViewAr} />
            <Route exact path="/v-solution-assignment/:id/:ref" component={SolCreateAssignment} />
            <Route exact path="/c-solution-usage/:id/:ref" component={SolCreateUsage} />
            <Route exact path="/v-solution-usage/:id/:ref" component={SolViewUsage} />
            <Route exact path="/v-solution-analysis/:id/:ref" component={SolExecutionView} />
            <Route exact path="/solution_usage_log_report/:id/:ref" component={SolUsageLogReport} />
            <Route exact path="/v-solution-preparation-approvals/:id/:ref" component={SolApprovalView} />
            <Route exact path="/v-sol-raw-data/:menu_id/:id/:ref" component={RawDataReport} />

            {/* Volumetric Solution Management */}
            <Route exact path="/c-vol-sol-creation/:id/:ref" component={CreateVolumetricSolution} />
            <Route exact path="/v-vol-sol-creation/:id/:ref" component={ViewVolumetricSolution} />
            <Route exact path="/c-vol-sol-preparation/:id/:ref" component={CreateVolumetricPreparation} />
            <Route exact path="/v-vol-sol-preparation/:id/:ref" component={ViewVolumetricPreparation} />
            <Route exact path="/c-vol-sol-preparation-initiation/:id/:ref" component={CreateInitation} />
            <Route exact path="/v-vol-sol-preparation-initiation/:id/:ref" component={ViewInitation} />
            <Route exact path="/v-vol-sol-preparation-execution/:id/:ref" component={VolExecutionView} />
            <Route exact path="/v-volumetric-solution-preparation-approvals/:id/:ref" component={VolApprovalView} />
            <Route exact path="/c-vol-sol-stand-intiation/:id/:ref" component={CreateStandardizationInitation} />
            <Route exact path="/v-vol-sol-stand-intiation/:id/:ref" component={ViewStandardizationInitation} />
            <Route exact path="/v-vol-sol-stand-execution/:id/:ref" component={StandardizationExecutionView} />
            <Route exact path="/v-vol-sol-stand-approvals/:id/:ref" component={StandardizationApprovalView} />
            <Route exact path="/c-vol-sol-usage/:id/:ref" component={VolCreateUsage} />
            <Route exact path="/v-vol-sol-usage/:id/:ref" component={VolViewUsage} />
            <Route exact path="/vol_sol_usage_log_report/:id/:ref" component={VolSolUsageLogReport} />
            <Route exact path="/v-vol-raw-data/:menu_id/:id/:ref" component={RawDataReport} />

            {/* LDMS */}
            <Route exact path="/c-ldms-document-types/:id/:ref" component={CreateDocumentType} />
            <Route exact path="/v-ldms-document-types/:id/:ref" component={ViewDocumentType} />
            <Route exact path="/c-ldms-document-uploads/:id/:ref" component={CreateDocumentUpload} />
            <Route exact path="/v-ldms-document-uploads/:id/:ref" component={ViewDocumentUpload} />
            <Route exact path="/c-ldms-document-module-mapping/:id/:ref" component={CreateDocumentMapping} />
            <Route exact path="/v-ldms-document-module-mapping/:id/:ref" component={ViewDocumentMapping} />
            <Route exact path="/c-ldms-document-archival/:id/:ref" component={CreateDocumentArchival} />
            <Route exact path="/v-ldms-document-archival/:id/:ref" component={ViewDocumentArchival} />

            {/* Environmental Monitoring */}
            <Route exact path="/c-env-grades/:id/:ref" component={CreateGrade} />
            <Route exact path="/v-env-grades/:id/:ref" component={ViewGrade} />
            <Route exact path="/c-monitoring-location/:id/:ref" component={CreateMonitoringLocation} />
            <Route exact path="/v-monitoring-location/:id/:ref" component={ViewMonitoringLocation} />
            <Route exact path="/c-monitoring-Schedule/:id/:ref" component={CreateMonitoringSchedule} />
            <Route exact path="/v-monitoring-Schedule/:id/:ref" component={ViewMonitoringSchedule} />
            <Route exact path="/v-env-analysis-in-process/:id/:ref" component={CreateAnalysisInprocess} />
            <Route exact path="/v-env-sample-acknowledgment/:id/:ref" component={CreateaAcknowledgment} />
            <Route exact path="/v-env-sample-for-analysis/:id/:ref" component={ViewSampleforAnalysis} />
            <Route exact path="/v-env-review-approval-of-analysis/:id/:ref" component={EnvApprovalView} />
            <Route exact path="/v-env-generated-analysis/:id/:ref" component={EmGeneratedView} />
            <Route exact path="/v-env-coa/:id/:ref" component={EmCoaView} />
            <Route exact path="/v-all-schedules/:id/:ref" component={CreateScheduleHoldRelease} />
            <Route exact path="/c-all-schedules/:id/:ref" component={CreateScheduleHoldRelease} />
            <Route exact path="/v-scheduler-hold/:id/:ref" component={ViewScheduleHoldRelease} />
            <Route exact path="/v-not-perform/:id/:ref" component={ViewNotPerform} />
            <Route exact path="/v-env-raw-data/:menu_id/:id/:ref" component={RawDataReport} />

            {/* Label Management */}
            <Route exact path="/c-label-stock/:id/:ref" component={CreateLabelStock} />
            <Route exact path="/v-label-stock/:id/:ref" component={ViewLabelStock} />
            <Route exact path="/c-label-data-entry/:id/:ref" component={CreateLabelDataEntry} />
            <Route exact path="/v-label-data-entry/:id/:ref" component={ViewLabelDataEntry} />
            <Route exact path="/c-label-template-design/:id/:ref" component={CreateLabelTemplateDesign} />
            <Route exact path="/v-label-template-design/:id/:ref" component={ViewLabelTemplateDesign} />
            <Route exact path="/v-label-print/:id/:ref" component={CreateLabelPrint} />
            <Route exact path="/c-label-re-print/:id/:ref" component={CreateLabelRePrint} />
            <Route exact path="/v-label-stock-report/:id/:ref" component={LabelStockReport} />
            <Route exact path="/v-label-re-print/:id/:ref" component={ViewLabelReprint}/>


            {/* Analyst Qualification */}
            <Route exact path="/c-aq-configuration/:id/:ref" component={CreateAQConfiguration} />
            <Route exact path="/v-aq-configuration/:id/:ref" component={ViewAQConfiguration} />
            <Route exact path="/c-aq-initiated/:id/:ref" component={CreateAQInitiated} />
            <Route exact path="/v-aq-initiated/:id/:ref" component={ViewAQInitiated} />
            <Route exact path="/v-aq-rq-pending/:id/:ref" component={ViewAQRQPending} />
            <Route exact path="/v-aq-rq-initiated/:id/:ref" component={ViewAQRQInitiated} />
            <Route exact path="/c-aq-ea-initiation/:id/:ref" component={CreateAQEAInitiation} />
            <Route exact path="/v-aq-ea-initiation/:id/:ref" component={ViewAQEAInitiation} />
            <Route exact path="/v-aq-execution/:id/:ref" component={AQExecution} />
            <Route exact path="/v-aq-ea-execution/:id/:ref" component={AQEAExecution} />
            <Route exact path="/v-aq-review-approval/:id/:ref" component={AQApprovalView} />
            <Route exact path="/v-aq-certificate/:id/:ref" component={AQViewCertificate} />
            <Route exact path="/c-aq-direct-qualification/:id/:ref" component={AQCreateDirectqualification} />
            <Route exact path="/v-aq-direct-qualification/:id/:ref" component={AQVieweDirectqualification} />
            <Route exact path="/v-aq-log/:id/:ref" component={AQViewCertificate} />

            {/* Media Managment */}
            <Route exact path="/c-media-name-creation/:id/:ref" component={CreateMediaName} />
            <Route exact path="/v-media-name-creation/:id/:ref" component={ViewMediaName} />
            <Route exact path="/c-media-preparation/:id/:ref" component={CreateMediaPreparation} />
            <Route exact path="/v-media-preparation/:id/:ref" component={ViewMediaPreparation} />
            <Route exact path="/c-media-inward/:id/:ref" component={CreateMediaInward} />
            <Route exact path="/v-media-inward/:id/:ref" component={ViewMediaInward} />
            <Route exact path="/v-media-prep-execution/:id/:ref" component={MediaExecutionView} />
            <Route exact path="/v-media-prep-review-approval/:id/:ref" component={MediaApprovalView} />
            <Route exact path="/v-media-inward-acknowledgement/:id/:ref" component={CreateMediaAcknowledgment} />
            <Route exact path="/v-media-inward-analysis/:id/:ref" component={MediaAnalysisView} />
            <Route exact path="/v-media-inward-approval/:id/:ref" component={MediaInwardApprovalView} />
            <Route exact path="/c-media-container-issuance/:id/:ref" component={CreateMediaIssuance} />
            <Route exact path="/v-media-container-issuance/:id/:ref" component={ViewMediaIssuance} />
            <Route exact path="/c-media-usage/:id/:ref" component={CreateMediaUsage} />
            <Route exact path="/v-media-usage/:id/:ref" component={ViewMediaUsage} />
            <Route exact path="/c-media-destruction/:id/:ref" component={CreateMediaDestruction} />
            <Route exact path="/v-media-destruction/:id/:ref" component={ViewMediaDestruction} />

            {/* Culture Managment */}
            <Route exact path="/c-culture-template/:id/:ref" component={CreateCultureTemplate} />
            <Route exact path="/v-culture-template/:id/:ref" component={ViewCultureTemplate} />
            <Route exact path="/c-culture-creation/:id/:ref" component={CreateCultureCreation} />
            <Route exact path="/v-culture-creation/:id/:ref" component={ViewCultureCreation} />
            <Route exact path="/c-culture-inward/:id/:ref" component={CreateCultureInward} />
            <Route exact path="/v-culture-inward/:id/:ref" component={ViewCultureInward} />
            <Route exact path="/v-culture-inward-aknowledge/:id/:ref" component={CreateCultureAcknowledgment} />
            <Route exact path="/v-culture-inward-analysis/:id/:ref" component={ViewCultureInwardAnalysis} />
            <Route exact path="/v-culture-inward-review-approval/:id/:ref" component={ViewCultureInwardApproval} />
            <Route exact path="/c-culture-in-generation/:id/:ref" component={CreateCultureGeneration} />
            <Route exact path="/v-culture-in-generation/:id/:ref" component={ViewCultureGeneration} />
            <Route exact path="/v-culture-in-generation-ar/:id/:ref" component={GenerateCultureAr} />
            <Route exact path="/v-culture-analysis/:id/:ref" component={ViewCultureAnalysis} />
            <Route exact path="/v-culture-review-and-approval/:id/:ref" component={ViewCultureApproval} />
            <Route exact path="/v-culture-generated/:id/:ref" component={ViewGeneration} />
            <Route exact path="/v-culture-generated-submition/:id/:ref" component={ViewGeneratiSubmition} />
            <Route exact path="/c-culture-usage/:id/:ref" component={CreateCultureUsage} />
            <Route exact path="/v-culture-usage/:id/:ref" component={ViewCultureUsage} />
            <Route exact path="/v-culture-generation-log/:id/:ref" component={CultureGenerationLogReport} />
            <Route component={PageNotFound} />

          </Switch>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  </MemoryRouter >
)

export default App;
